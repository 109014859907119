@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  background: white;
}

code {
  font-family: 'Inter', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  color: #282828;
}
a {
  text-decoration: none;
  color: black;
}
p{
  color: #575757;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: white;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
  z-index: 999;
}
.logo {
  max-width: 200px;
  width: 100%;
}
.btn-brand-primary {
  background: linear-gradient(45deg, #4c2c78, #992c8d);
  padding: 5px 15px;
  border-radius: 5px;
  color: #f2f2f2;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(225, 224, 224, 0.2);
  transition: all 0.2s ease-in;

}
.btn-white {
  background: white;
  padding: 5px 15px;
  border-radius: 5px;
  color: #644184;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(225, 224, 224, 0.2);
  transition: all 0.2s ease-in;
}
.btn-brand-primary-outline {
  background: transparent;
  border: 1px solid #f2f2f2;
  padding: 5px 15px;
  border-radius: 5px;
  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px rgba(225, 224, 224, 0.2);
  transition: all 0.2s ease-in;
}
.btn-brand-primary:hover {
  box-shadow: 0 0 5px rgba(225, 224, 224, 0.4);
  transform: scale(0.99);
}
.btn-brand-primary-outline:hover {
  box-shadow: 0 0 5px rgba(225, 224, 224, 0.4);
  transform: scale(0.99);
}
.btn-white:hover {
  box-shadow: 0 0 5px rgba(225, 224, 224, 0.4);
  transform: scale(0.99);
}
a.active {
  color: #644184;
  font-weight: 500;
}
.ham-btn {
  font-size: 25px;
  padding: 0;
  border: none;
  background: transparent;
  display: none;
}

.top-bar {
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.top-bar ul li a {
  color: #4c4a4a;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}


.main {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
}
.hero {
  padding: 60px 0;
  background: url(./../images/MacBook\ Air\ -\ 1.png);
  background-position: center;
  background-size: cover;
}
.hero h3 {
  color: #f2f2f2;
}
.hero p {
  color: #f2f2f2;
}
.features {
  padding: 50px 0;
  margin: 20px 0;
}
.features-list {
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 35px 25px;
  margin: 10px;
  background: white;
}
.features-list h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0;
}
.features-list svg {
  font-size: 25px;
  color: #522c7a;
  margin-bottom: 10px;
}
.choose-us-img {
  max-width: 450px;
  width: 100%;
  border-radius: 10px;
}
.why-choose-us ul li svg {
  font-size: 15px;
  color: #522c7a;
}
.btn-nav button {
  border: none;
  border-radius: 50%;
  font-size: 20px;
  width: 40px;
  height: 40px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-nav button:hover {
  border: 2px solid #4c2b77;
}
.title-img {
  width: 200px !important;
  height: 55px !important;
  margin: 0 !important;
}
.product {
  box-shadow: 0 0 2px rgba(0,0,0,0.5);
  padding: 20px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  width: 100%;
  text-align: center;
}
.product:hover {
  box-shadow: 0 0 4px rgba(0,0,0,0.3);
}
.product img {
  margin: 20px 0;
}

.product h3 {
  font-size: 16px;
}
.product p {
  font-size: 14px;
  margin: 10px 0;
}
.product img {
  width: 100%;
  height: 200px;
}
.nova h3, .lima h3, .byon h3, .sierra h3 {
  font-size: 18px;
  color: #4c2b79;
  font-weight: bold;
  margin-bottom: 15px;

}
.nova h4, .lima h4, .byon h4, .sierra h4 {
  font-size: 16px;
  color: #4c2c7a;
  border-left: 2px solid #4c2b79;
  padding-left: 10px;
  margin-bottom: 15px;
}
.nova-img, .lima-img, .byon-img, .sierra-img {
  width: 100%;
  max-width: 300px;
  height: 250px;
}
.enquiry-model {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.enquiry-container {
  max-width: 400px;
  width: 100%;
  box-shadow: 0 0 3px rgba(0,0,0,0.3);
  padding: 20px;
  height: fit-content;
}
.enquiry-container h3 {
  font-size: 16px;
}
.enquiry-container p {
  font-size: 12px;
}
.form-input::placeholder {
  font-size: 12px;
}
.footer h4 {
  font-size: 16px;
}
.social-link a {
  font-size: 20px;
}
.about-img {
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
}
.heading-txt {
  background: linear-gradient(45deg, #4c2c78, #992c8d);
  margin-bottom: 20px;
}
.heading-txt h2 {
  font-size: 25px;
  color: #f2f2f2; 
  margin: 0;
}
.about h3 {
  font-size: 18px;
}
form label {
  margin-bottom: 5px;
  font-size: 12px;
}
.form-input {
  font-size: 13px;
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(0,0,0,0.3);
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.footer-bottom p {
  margin: 0;
}

.mobile-menu {
  display: none;
}
@media  (max-width: 900px) {
  .ham-btn {
    display: block;
    margin: 10px;
  }
  .nav-menu, .enquire {
    display: none !important;
  }
  .mobile-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #ffffffba;
    height: 100vh;
}
  .mobile-nav-list {
    position: absolute;
    background: white;
    left: 0;
    top: 0;
    height: 100vh;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);

}
.mobile-nav-list ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.mobile-nav-list ul li {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 5px;
}
.close-menu {
  position: absolute;
  right: -35px;
  top: 10px;
  border: none;
  background: #912f8a;
  color: white;
  border-radius: 3px;
}
.top-bar {
  display: none;
}
}
@media  (max-width: 400px) {
  .footer-bottom p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 10px;
}
.footer-bottom {
  flex-direction: column;
  gap: 0;
}
}

@media  (max-width: 400px) {
  .features-list {
    padding: 15px;
    margin: 5px 0;
}
}